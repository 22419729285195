//== DEFAULTS RAILS
  import Rails from "@rails/ujs"
  Rails.start()


//== JQUERY
  import $ from 'jquery'
  window.$ = window.jQuery = $

  // Passive event listeners
  require('default-passive-events');

  // external Content
  $(document).ready(function () {
    $(".show-external-content").on("click", function () {
      //alert($(this).closest(".card-consent-external-content"));
      $(this).closest(".card-consent-external-content").replaceWith($(this).data("replacement"));
    });
  });


//== BOOTSTRAP
  require("bootstrap")

//
//
// MOBILE MENUE
require("mmenu-js/dist/mmenu");
document.addEventListener(
  "DOMContentLoaded", () => {
    new Mmenu( "#mobilemenu", {
      "setSelected": {
        "parent": true
      },
      "navbar": {
        "title": "Menü"
      },
    });
  }
);


//
//
// FANCYBOX
import { fancybox } from "components/fancybox";


//
//
// TYPEAHEAD
import { typeahead } from "jquery-typeahead/dist/jquery.typeahead.min";
